import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Box, Typography, Grid } from "@mui/material";
import { passwordResetConfirmValidationSchema } from "../../utilities/validationSchemas";

const ResetPasswordConfirm = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const verifyEmailForPasswordReset = async (newPassword, reNewPassword) => {
    // Call the resetPasswordConfirm function from authService
    const response = await authService.resetPasswordConfirm(
      uid,
      token,
      newPassword,
      reNewPassword
    );

    if (response.success) {
      // Verification successful, you can redirect or show a success message
      navigate("/login");
    } else {
      // Check for specific keys in response.error
      if ("uid" in response.error || "token" in response.error) {
        toast.error("დაფიქსირდა შეცდომა, თავიდან სცადეთ");
        navigate("/request-reset-password");
      } else {
        // Toast each error value
        for (let key in response.error) {
          toast.error(String(response.error[key]));
        }
        return;
      }
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
      padding={1}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Box sx={{ width: "100%", textAlign: "center", marginBottom: 5 }}>
            <Typography variant="h4" component="h1" color="grey.dark">
              პაროლის შეცვლა
            </Typography>
          </Box>
          <Formik
            initialValues={{ newPassword: "", reNewPassword: "" }}
            validationSchema={passwordResetConfirmValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              verifyEmailForPasswordReset(
                values.newPassword,
                values.reNewPassword
              );
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <Box marginBottom={2}>
                  <Field
                    as={TextField}
                    type="password"
                    name="newPassword"
                    label="ახალი პაროლი"
                    variant="outlined"
                    error={touched.newPassword && !!errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    fullWidth
                  />
                </Box>

                <Box marginBottom={2}>
                  <Field
                    as={TextField}
                    type="password"
                    name="reNewPassword"
                    label="გაიმეორე პაროლი"
                    variant="outlined"
                    error={touched.reNewPassword && !!errors.reNewPassword}
                    helperText={touched.reNewPassword && errors.reNewPassword}
                    fullWidth
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  style={{ color: "white", height: "2.8em", fontSize: "1.2em" }}
                  fullWidth
                >
                  დადასტურება
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPasswordConfirm;
