import { Button } from "@mui/material";

const SubmitButton = ({ children, ...props }) => {
  return (
    <Button
    fullWidth
    type="submit"
      variant="contained"
      color="primary"
      style={{
        color: "#0F1E3C",
        backgroundColor: "#F5D24B",
        border: "none",
        borderRadius: 1,
        fontWeight: "bold",
        fontSize: "1.2rem",
        py: "2em",
        "&:hover": {
          backgroundColor: "#dbb212",
          color: "#0F1E3C",
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};


export default SubmitButton;