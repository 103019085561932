import httpService from './httpService';
import handleRequest from '../utilities/handleRequest';

import authHeader from '../utilities/authHeader';

/**
 * Service for handling authentication related operations.
 * @namespace authService
 */
const authService = {
    // Login
    login: async (email, password) => {
        const request = httpService.post(`auth/jwt/create/`, {email, password});
        return await handleRequest(request);
    },

    // Register
    register: async (data) => {
        // Construct the birth date string suitable for the API DateField: "YYYY-MM-DD"
        const birthDate = `${data.birthYear}-${data.birthMonth.toString().padStart(2, '0')}-${data.birthDay.toString().padStart(2, '0')}`;

        const payload = {
            "email": data.email,
            "phone": data.phone,
            "password": data.password,
            "re_password": data.confirmPassword,
            "license": data.license,
            "profile": {
                "first_name": data.firstName,
                "last_name": data.lastName,
                "company": data.company,
                "industry": data.industry,
                "position": data.position,
                "citizenship": data.countryOfCitizenship,
                "residence": data.countryOfResidence,
                "sex": data.sex,
                "date_of_birth": birthDate
            }
        }


        const request = httpService.post(`auth/users/`, payload);
        return handleRequest(request);
    },

    // Send Activation Email
    sendActivation: async (uid, token) => {
        const request = httpService.post(`auth/users/activation/`, {uid, token});
        return handleRequest(request);
    },

    // Resend Activation Email
    resendActivation: async (email) => {
        const request = httpService.post(`auth/users/resend_activation/`, {email});
        return handleRequest(request);
    },

    // Request Password Reset
    requestPasswordReset: async (email) => {
        const request = httpService.post(`auth/users/reset_password/`, {email});
        return handleRequest(request);
    },

    // Password Reset Confirm
    resetPasswordConfirm: async (uid, token, newPassword, reNewPassword) => {
        const request = httpService.post(`auth/users/reset_password_confirm/`, {
            uid,
            token,
            new_password: newPassword,
            re_new_password: reNewPassword
        });
        return handleRequest(request);
    },

    changePassword: async (data) => {
        const payload = {
            "new_password": data.newPassword,
            "re_new_password": data.reNewPassword,
            "current_password": data.currentPassword
        }

        const request = httpService.post(`auth/users/set_password/`, payload, {headers: authHeader()});
        return handleRequest(request);
    },

    profileAbout: async () => {
        const request = httpService.get(
            `auth/profile/about/`, {headers: authHeader()}
        );
        return handleRequest(request);
    },

    profileDetails: async () => {
        const request = httpService.get(
            `auth/profile/details/`, {headers: authHeader()}
        );
        return handleRequest(request);
    },

    profileContactInformation: async () => {
        const request = httpService.get(
            `auth/profile/contact/`, {headers: authHeader()}
        );
        return handleRequest(request);
    },

    updateProfile: async (data) => {
        const payload = {
            "first_name": data.first_name,
            "last_name": data.last_name,
            "company": data.company,
            "industry": data.industry,
            "position": data.position,
            "citizenship": data.citizenship,
            "residence": data.residence
        }

        const request = httpService.patch(
            `auth/profile/details/`, payload, {headers: authHeader()}
        );
        return handleRequest(request);
    },

    licenseKeyDetails: async () => {
        const request = httpService.get(
            `auth/licenses/current`, {headers: authHeader()}
        );
        return handleRequest(request);
    },

    renewLicenseKey: async (licenseKey) => {
        const request = httpService.post(
            `auth/licenses/renew/`, {license_key: licenseKey}, {headers: authHeader()}
        );
        return handleRequest(request);
    },

    deleteAccount: async (currentPassword) => {
        const request = httpService.delete(
            "auth/users/me/", {headers: authHeader(), data: {
                current_password: currentPassword
            }}
        );
        return handleRequest(request);
    },
};

export default authService;
