// Function to return the authorization header with JWT token
const authHeader = () => {
    const accessToken = localStorage.getItem('jwt-access');
    
    if (accessToken) {
      return { Authorization: 'JWT ' + accessToken };
    } else {
      return {};
    }
  };
  
  export default authHeader;
  