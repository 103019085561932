import React from 'react';
import {Box, List, IconButton, Tooltip} from '@mui/material';
import {Settings, HelpOutline, History, Bookmarks, TravelExplore, AutoAwesome} from '@mui/icons-material';
import {Link} from 'react-router-dom';

import LogoIcon from '../../assets/media/logos/logoIconBlue.png';

const MapSidebar = () => {
    return (
        <Box
            sx={{
                width: 90, // Fixed width for the thin sidebar
                height: '100%', // Full height
                backgroundColor: "background.paper",
                color: "text.primary",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxShadow: 'rgba(136, 165, 191, 0.8) 6px 2px 16px 0px, rgba(255, 255, 255, 0.9) -6px -2px 16px 0px',
                zIndex: 1000,
                '.MuiIconButton-root': { // Targeting all IconButton components inside the Box
                    margin: '8px',
                    '& svg': {
                        fontSize: '1.8em',
                    },
                },
            }}
        >
            <List
                sx={{padding: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                {/* Top Icon Group */}
                <Tooltip title={'Home'} placement={'right'}>
                    <IconButton color="inherit" component={Link} to='/'>
                        {/* Render icon image*/}
                        <img src={LogoIcon} style={{width: '90%', height: 'auto'}} alt="Logo"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Link Search'} placement={'right'}>
                    <IconButton color="inherit"><TravelExplore/></IconButton>
                </Tooltip>
                <Tooltip title={'Create Virtual Property'} placement={'right'}>
                    <IconButton color="inherit"><AutoAwesome/></IconButton>
                </Tooltip>
                <Tooltip title={'Saved Real Estate'} placement={'right'}>
                    <IconButton color="inherit"><Bookmarks/></IconButton>
                </Tooltip>
                <Tooltip title={'Search History'} placement={'right'}>
                    <IconButton color="inherit"><History/></IconButton>
                </Tooltip>
            </List>
            <List sx={{padding: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                {/* Bottom Icon Group */}
                <Tooltip title={'Settings'} placement={'right'}>
                    <IconButton color="inherit"><Settings/></IconButton>
                </Tooltip>
                <Tooltip title={'Support'} placement={'right'}>
                    <IconButton color="inherit"><HelpOutline/></IconButton>
                </Tooltip>
            </List>
        </Box>
    )
        ;
};

export default MapSidebar;
