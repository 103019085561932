import React, {createContext, useState, useEffect} from "react";
import {isExpired, decodeToken} from "react-jwt";
import {refreshToken} from "../services/httpService"; // Import the refreshToken function

export const AuthContext = createContext();

/**
 * Provides authentication context for the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const updateUserState = async () => {
        try {
            setIsLoading(true);

            let token = localStorage.getItem("jwt-access");

            if (token && isExpired(token)) {
                token = await refreshToken();
            }

            if (token) {
                const decodedToken = decodeToken(token);
                if (decodedToken) {
                    setCurrentUser({
                        id: decodedToken.user_id,
                        email: decodedToken.email,
                    });

                    /*
                    Access token payload example:
                    {
                      "token_type": "access",
                      "exp": 1707056879,
                      "iat": 1707046079,
                      "jti": "2de970131deb46f0b2ec579ef30f4ec5",
                      "user_id": 1,
                      "email": "user@gmail.com"
                    }
                    */
                }
            } else {
                setCurrentUser(null);
            }
        } catch (error) {
            alert("Error updating user state:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        updateUserState();
    }, []);

    // Infer isAuthenticated from currentUser
    const isAuthenticated = currentUser !== null;

    return (
        <AuthContext.Provider value={{currentUser, setCurrentUser, updateUserState, isLoading, isAuthenticated}}>
            {children}
        </AuthContext.Provider>
    );
};
