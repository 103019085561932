import {useTranslation} from "react-i18next";
import {Box, Drawer, List, ListItem, ListItemText, Typography} from "@mui/material";
import {Link} from "react-router-dom";

import LanguageSwitcher from "../tooltips/languageSwitcher";

const NavBarDrawer = ({isSidebarOpen, toggleSidebar, navRoutes, isAuthenticated}) => {
    const {t} = useTranslation();

    return (<Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={toggleSidebar}
    >
        <Box
            sx={{width: 250, height: "100%", padding: 2, backgroundColor: "#2D5FB9"}}
            role="presentation"
            onClick={toggleSidebar}
            onKeyDown={toggleSidebar}
        >
            <List>
                {navRoutes.map((route, index) => (<ListItem component={Link} to={route.path} key={index}>
                    <ListItemText
                        primary={<Typography
                            sx={{
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "1.3rem",
                                textAlign: "right",
                                "&:hover": {
                                    color: "#CCC",
                                },
                            }}
                        >
                            {t(route.name)}
                        </Typography>}
                    />
                </ListItem>))}
                {isAuthenticated ? (
                    <>
                        <ListItem component={Link} to="/logout">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: "1.3rem",
                                            textAlign: "right",
                                            "&:hover": {
                                                color: "#CCC",
                                            },
                                        }}
                                    >
                                        {t("navbar.logout")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem component={Link} to="/profile">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: "1.3rem",
                                            textAlign: "right",
                                            "&:hover": {
                                                color: "#CCC",
                                            },
                                        }}
                                    >
                                        {t("navbar.profile")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </>
                ) : (
                    <>
                        <ListItem component={Link} to="/login">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: "1.3rem",
                                            textAlign: "right",
                                            "&:hover": {
                                                color: "#CCC",
                                            },
                                        }}
                                    >
                                        {t("navbar.login")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem component={Link} to="/register">
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: "1.3rem",
                                            textAlign: "right",
                                            "&:hover": {
                                                color: "#CCC",
                                            },
                                        }}
                                    >
                                        {t("navbar.get_started")}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </>
                )}
                <ListItem
                    sx={{display: "flex", justifyContent: "flex-end", color: "white"}}
                >
                    <LanguageSwitcher />
                </ListItem>
            </List>
        </Box>
    </Drawer>);
};

export default NavBarDrawer;