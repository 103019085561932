import React from "react";
import { Typography } from "@mui/material";

const TermsAndConditions = (
    <>
        <Typography variant="h6" gutterBottom>
            Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
            Welcome to EstReaI.ge, operated by EstReaI. These Terms and Conditions
            ("Terms") govern your use of our website and services offered through it.
            By accessing or using our website, you agree to comply with these Terms.
            If you do not agree with any part of these Terms, you must not use our
            website.
        </Typography>

        <Typography variant="h6" gutterBottom>
            1. Use of Website
        </Typography>
        <Typography variant="body1" paragraph>
            1.1. You must be at least 18 years old to use our website. By using our
            website, you represent and warrant that you are at least 18 years of age.
            1.2. You agree to use our website only for lawful purposes and in
            accordance with these Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
            2. Real Estate Investment
        </Typography>
        <Typography variant="body1" paragraph>
            2.1. EstReal facilitates real estate investment opportunities through our
            platform. However, we do not provide any guarantees regarding the
            performance, returns, or success of any investment opportunity presented
            on our website. 2.2. Investments involve risks, including the potential
            loss of capital. You should conduct your own research and seek advice from
            qualified professionals before making any investment decisions.
        </Typography>

        <Typography variant="h6" gutterBottom>
            3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
            3.1. In order to access certain features of our website, you may need to
            create a user account. You are responsible for maintaining the
            confidentiality of your account information and for all activities that
            occur under your account. 3.2. You agree to provide accurate and complete
            information when creating your user account and to update your information
            promptly if it changes.
        </Typography>

        <Typography variant="h6" gutterBottom>
            4. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
            4.1. The content on our website, including text, images, graphics, logos,
            and other materials, is protected by copyright and other intellectual
            property rights owned by EstReaI or our licensors. 4.2. You may not
            reproduce, distribute, modify, or create derivative works of any content
            from our website without prior written consent from EstReal.
        </Typography>

        <Typography variant="h6" gutterBottom>
            5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
            5.1. EstReal shall not be liable for any direct, indirect, incidental,
            special, consequential, or punitive damages arising out of or related to
            your use of our website or any investment opportunity presented on our
            platform. 5.2. In no event shall EstReaI's total liability to you for all
            damages exceed the amount paid by you, if any, for accessing or using our
            website.
        </Typography>

        <Typography variant="h6" gutterBottom>
            6. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
            6.1. These Terms shall be governed by and construed in accordance with the
            laws of Georgia, without regard to its conflict of law principles.
        </Typography>

        <Typography variant="h6" gutterBottom>
            7. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
            7.1. EstReal reserves the right to modify or update these Terms at any
            time. We will notify you of any changes by posting the updated Terms on
            our website. Your continued use of our website after any such changes
            constitutes your acceptance of the new Terms.
        </Typography>
    </>
);

export default TermsAndConditions;