import React from 'react';
import {useField} from 'formik';
import {Box, TextField, InputAdornment} from '@mui/material';

const RangeInput = ({label, name, unit}) => {
    // Use Formik's useField hook to connect to the form state
    const [fieldFrom, , helpersFrom] = useField(`${name}[0]`);
    const [fieldTo, , helpersTo] = useField(`${name}[1]`);

    const handleChange = (index) => (event) => {
        const value = event.target.value;
        if (index === 0) {
            helpersFrom.setValue(value === '' ? null : value);  // Convert empty string back to null for storage
        } else {
            helpersTo.setValue(value === '' ? null : value);
        }
    };

    return (
        <Box display="flex" alignItems="center" gap={2} p={2}>
            <TextField
                label={`From ${label}`}
                variant="standard"
                value={fieldFrom.value || ''}
                onChange={handleChange(0)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                }}
                sx={{width: '160px'}} // Set a fixed width for the TextField
            />
            <TextField
                label={`To ${label}`}
                variant="standard"
                value={fieldTo.value || ''}
                onChange={handleChange(1)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                }}
                sx={{width: '160px'}} // Set a fixed width for the TextField
            />
        </Box>
    );
};

export default RangeInput;