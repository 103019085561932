import React from 'react';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const TokenExpireDialog = ({ open, onClose }) => {
    const navigate = useNavigate();
    
    const onConfirm = () => {
        navigate("/login");
        onClose();
    }

    return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          თქვენი სესია ამოიწურა და საჭიროა თავიდან ავტორიზაცია.
          გადავიდეთ ავტორიზაციის გვერდზე? (თქვენი შეუნახავი ინფორმაცია შესაძლოა დაიკარგოს)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          არა
        </Button>
        <Button onClick={onConfirm} color="primary">
          ავტორიზაცია
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TokenExpireDialog;
