import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// Importing the translation files
import translationEN from "./locales/en.json"; // English
import translationKA from "./locales/ka.json"; // Georgian
import translationAR from "./locales/ar.json"; // Arabic

const resources = {
    ka: {
        translation: translationKA,
    },
    en: {
        translation: translationEN,
    },
    ar: {
        translation: translationAR,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

const language = localStorage.getItem("language");
if (language) {
    i18n.changeLanguage(language);
}

export default i18n;
