import React from 'react';
import {Box, useMediaQuery, useTheme} from '@mui/material';

import MapBox from '../../components/map/mapBox';
import MapSidebar from '../../components/navigation/mapSidebar';
import MapBottomNavigation from '../../components/navigation/mapBottomNavigation';

const MapPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: {
                xs: 'column',
                sm: 'row',
            },
        }}
        >
            {/* Conditional rendering of the Sidebar for non-mobile screens */}
            {!isMobile && (
                <Box sx={{width: 'auto', flexShrink: 0}}>
                    <MapSidebar/>
                </Box>
            )}

            {/* Map Layer */}
            {/* This box will grow to fill the remaining space */}
            <Box sx={{flexGrow: 1, height: "100%", width: "100%"}}>
                <MapBox/>
            </Box>

            {/* Conditional rendering of the Bottom Navigation for mobile screens */}
            {isMobile && (
                <Box sx={{width: '100%', height: "56px", zIndex: 1000}}>
                    <MapBottomNavigation/>
                </Box>
            )}
        </Box>
    );
};

export default MapPage;
