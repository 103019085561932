import React from 'react';
import { Field, ErrorMessage } from 'formik';
import {
    Grid,
    FormControl,
    FormLabel,
    FormControlLabel,
    TextField,
    MenuItem,
    Select,
    Radio,
    RadioGroup,
} from '@mui/material';
import { useTranslation } from "react-i18next";

import CountrySelect from '../fields/CountrySelect';


const PersonalDetailsFormStage = ({ formData, setFormData }) => {
    const { t } = useTranslation();

    const currentYear = new Date().getFullYear();
    const years = Array.from(
        { length: currentYear - 1900 },
        (_, i) => currentYear - i
    );
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Field
                        as={TextField}
                        name="company"
                        type="text"
                        label={t("registerPage.personalDetailsFormStage.company")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="company"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Field
                        as={TextField}
                        name="industry"
                        type="text"
                        label={t("registerPage.personalDetailsFormStage.industry")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="industry"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Field
                        as={TextField}
                        name="position"
                        type="text"
                        label={t("registerPage.personalDetailsFormStage.position")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="position"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>

                {/* Country of Residence and Country of Citizenship */}
                <Grid item xs={6}>
                    <CountrySelect
                        name="countryOfResidence"
                        label={t("registerPage.personalDetailsFormStage.countryOfResidence")}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CountrySelect
                        name="countryOfCitizenship"
                        label={t("registerPage.personalDetailsFormStage.countryOfCitizenship")}
                    />
                </Grid>



                {/* Date of Birth: Year, Month, Day */}
                <Grid item xs={12}>
                    <FormLabel component="legend">
                        {t("registerPage.personalDetailsFormStage.dateOfBirth")}
                    </FormLabel>
                </Grid>
                <Grid item xs={4}>
                    <Field as={Select} name="birthYear" displayEmpty fullWidth>
                        <MenuItem value="" disabled>
                            {t("registerPage.personalDetailsFormStage.year")}
                        </MenuItem>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Field>
                    <ErrorMessage
                        name="birthYear"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field as={Select} name="birthMonth" displayEmpty fullWidth>
                        <MenuItem value="" disabled>
                            {t("registerPage.personalDetailsFormStage.month")}
                        </MenuItem>
                        {months.map((month) => (
                            <MenuItem key={month} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </Field>
                    <ErrorMessage
                        name="birthMonth"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field as={Select} name="birthDay" displayEmpty fullWidth>
                        <MenuItem value="" disabled>
                            {t("registerPage.personalDetailsFormStage.day")}
                        </MenuItem>
                        {days.map((day) => (
                            <MenuItem key={day} value={day}>
                                {day}
                            </MenuItem>
                        ))}
                    </Field>
                    <ErrorMessage
                        name="birthDay"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>

                {/* Sex */}
                <Grid item xs={12}>
                    <Field name="sex">
                        {({ field }) => (
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">
                                    {t("registerPage.personalDetailsFormStage.gender")}
                                </FormLabel>
                                <RadioGroup
                                    {...field}
                                    row
                                    defaultValue="M"
                                    sx={{ justifyContent: "center", display: "flex" }}
                                >
                                    <FormControlLabel
                                        value="M"
                                        control={<Radio />}
                                        label={t("registerPage.personalDetailsFormStage.genderMale")}
                                    />
                                    <FormControlLabel
                                        value="F"
                                        control={<Radio />}
                                        label={t("registerPage.personalDetailsFormStage.genderFemale")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    </Field>
                    <ErrorMessage
                        name="sex"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PersonalDetailsFormStage;
