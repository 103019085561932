import React from "react";
import { Card, CardContent, Typography, Box, Button, IconButton } from "@mui/material";

import { Bookmark } from "@mui/icons-material";

const MarkerPopup = ({ listing }) => {
  const formattedPrice = new Intl.NumberFormat("en-US").format(listing.price);
  const formattedObjectivePrice = new Intl.NumberFormat("en-US").format(
    listing.objective_price
  );

  const difference = listing.objective_price - listing.price;
  const percentageDifference = (difference / listing.price) * 100;

  let barPosition;
  if (percentageDifference > 100) barPosition = '100%';
  else if (percentageDifference < -100) barPosition = '0%';
  else barPosition = `${50 + percentageDifference / 2}%`;
  


  return (
    <Card
      sx={{
        backgroundColor: "background.paper",
        boxShadow: "0",
        margin: 0,
        padding: 0,
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Box display="flex" gap=".25em">
          <Button
            variant="contained"
            sx={{
              width: "90%",
              height: "35px",
              fontWeight: "bold",
              backgroundColor: "#2D5FB9",
              borderRadius: "8px",
              margin: 0,
              padding: 0,
              fontSize: 15,
            }}
          >
            {listing.redcat_id}
          </Button>
          <IconButton
            variant="contained"
            sx={{
              height: "35px",
              fontSize: 12,
              backgroundColor: "#2D5FB9",
              color: "white",
              borderRadius: "8px",
            }}
          >
            <Bookmark />
          </IconButton>
        </Box>

        {listing.objective_price != null ? (
          <>
            <Box
              sx={{
                height: "35px",
                backgroundColor: "lightgray",
                borderRadius: "8px",
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  py: .7
                }}
              >
                Price Difference: {" "}
                {(
                  ((listing.objective_price - listing.price) /
                    listing.price) *
                  100
                ).toFixed(2)}%
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'relative',
                background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 50%, rgba(0,255,0,1) 100%)",
                borderRadius: "8px",
                height: "35px",
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: barPosition,
                  transform: 'translateX(-50%)',
                  width: '4px',
                  height: '100%',
                  background: '#444',
                  borderRadius: '2px'
                }}
              />
            </Box>

          </>
        ) : (
          <Box
            sx={{ backgroundColor: "#2D5FB9", borderRadius: "8px", height: "35px" }}
          >
            <Typography
              variant="body1"
              sx={{
                py: ".2em",
                px: ".2em",
                textAlign: "center",
                color: "white",
                fontWeight: "bold",
                fontSize: "1.4em",
              }}
            >
              Not Analyzed Yet
            </Typography>
          </Box>
        )}

        <Typography
          variant="h6"
          component="div"
          sx={{ fontSize: 16, fontWeight: "bold", mt: 1 }}
        >
          Ask Price: <span style={{ float: "right" }}>${formattedPrice}</span>

        </Typography>

        {listing.objective_price != null && (
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: 16, fontWeight: "bold", mb: 1 }}
          >
            Analyzed Price: <span style={{ float: "right" }}>${formattedObjectivePrice}</span>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MarkerPopup;
