import React from "react";
import {useTranslation} from "react-i18next";
import {Typography, Box, Button, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import bgHorizontal from "../../assets/media/backgrounds/bgHorizontal.jpg";
import bgVertical from "../../assets/media/backgrounds/bgVertical.jpg";
import mobilePreview from "../../assets/media/backgrounds/mobilePreview.png";

import ContactUsFooter from "../../components/forms/contactUsFooter";

const HomePage = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {/* Background and Content */}
            <Box
                sx={{
                    position: "relative",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay color and opacity
                        zIndex: 1,
                    },
                    backgroundImage: `url(${bgHorizontal})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    zIndex: 0,
                    width: "100vw",
                    height: "100%", // Full viewport height (layout will grow to fill the screen)
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                {/* Left Side: Title and Slogan */}
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "40%",
                        },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: matches ? "1em" : 0,
                        textAlign: "left",
                        color: "white",
                        zIndex: 1, // Ensure the content is above the overlay
                    }}
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        fontWeight="bold"
                        sx={{
                            fontSize: {
                                xs: "2.8rem",
                                sm: "3rem",
                            },
                            textAlign: "left"
                        }}
                    >
                        {t("homepage.title")}
                    </Typography>
                    <Typography
                        fontWeight="bold"
                        variant="h6"
                        component="h2"
                        mb={3}
                        mt={1}
                    >
                        {t("homepage.slogan")}
                    </Typography>
                    <Typography fontWeight="bold" variant="subtitle1" component="h3" mb={3}>
                        {t("homepage.sub_slogan1_line1")} <br/>
                        {t("homepage.sub_slogan1_line2")}
                    </Typography>
                    <Typography
                        sx={{
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                        }}
                        fontWeight="bold"
                        variant="subtitle1"
                        component="h6"
                    >
                        {t("homepage.sub_slogan2")}
                    </Typography>
                    <Button
                        component={Link}
                        to="/map"
                        sx={{
                            color: "#0F1E3C",
                            backgroundColor: "#F5D24B",
                            border: "none",
                            borderRadius: 1,
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            padding: 1.5,
                            margin: "1em auto",
                            "&:hover": {
                                backgroundColor: "#dbb212",
                                color: "#0F1E3C",
                            },
                        }}
                    >
                        {t("homepage.exploreMap")}
                    </Button>
                </Box>
                {!matches && (
                    <Box
                        component="img"
                        src={mobilePreview}
                        alt="Descriptive Alt Text"
                        sx={{maxWidth: "40%", height: "70vh", zIndex: 1}}
                    />
                )}
            </Box>

            {/* Footer */}
            {/* <ContactUsFooter /> */}
        </>
    );
};

export default HomePage;
