import React, {useState} from 'react';
import {BottomNavigation, BottomNavigationAction, IconButton, Paper} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/TravelExplore'; // Assuming "Favorites" is "Link Search"
import LocationOnIcon from '@mui/icons-material/AutoAwesome'; // Assuming "Nearby" is "Create Virtual Property"
import SavedIcon from '@mui/icons-material/Bookmarks'; // "Saved Listings"
import {Link} from 'react-router-dom';
import LogoIcon from "../../assets/media/logos/logoIconBlue.png";

const MapBottomNavigation = () => {
    const [value, setValue] = useState(0);

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={4}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}

            >
                <BottomNavigationAction
                    icon={
                        <IconButton color="inherit" component={Link} to='/'>
                            {/* Render icon image*/}
                            <img src={LogoIcon} style={{width: '40px', height: 'auto'}} alt="Logo"/>
                        </IconButton>
                    }
                    component={Link}
                    to="/"
                />
                <BottomNavigationAction
                    icon={<FavoriteIcon sx={{fontSize: '2rem'}}/>}
                />
                <BottomNavigationAction
                    icon={<LocationOnIcon sx={{fontSize: '2rem'}}/>}
                />
                <BottomNavigationAction
                    icon={<SavedIcon sx={{fontSize: '2rem'}}/>}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default MapBottomNavigation;
