import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import { toast } from "react-toastify";
import LoadingPage from "../loadingPage";

const VerifyEmail = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      // Call the sendActivation function from authService
      const response = await authService.sendActivation(uid, token);

      if (response.success) {
        // Verification successful, you can redirect or show a success message
        toast.success("თქვენი ანგარიში წარმატებით გააქტიურდა");
        navigate("/login");
      } else {
        // Verification failed, you can redirect or show an error message
        toast.error("დაფიქსირდა შეცდომა, თავიდან სცადეთ");
        navigate("/");
      }
    };

    verifyEmail();
  }, []);

  return <LoadingPage />;
};

export default VerifyEmail;
