export const legendShapes = {
    square: { width: '20px', height: '20px', backgroundColor: '#2D5FB9' },
    circle: { width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#2D5FB9' },
    rotatedSquare: { width: '18px', height: '18px', backgroundColor: '#2D5FB9', transform: 'rotate(45deg)' },
    triangle: {
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '20px solid #2D5FB9',
    },
    rotatedTriangle: {
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '20px solid #2D5FB9',
    },
};
