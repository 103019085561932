import { createTheme } from "@mui/material/styles";

const colors = {
  primaryDark: "#0E0887",
  primaryLight: "#2D5FB9",
  secondaryMain: "#E6E6E6",
  backgroundPaper: "#E6E6E6",
  backgroundDefault: "#FFFFFF",
  textPrimary: "#252525",
  textSecondary: "#393939",
  divider: "#2f2f2f",
  greyDark: "#000012",
  greyLight: "#545454",
};

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        /* Customize scrollbar width, height, and color */
        "*::-webkit-scrollbar": {
          width: "5px", /* Width of the scrollbar */
          height: "5px", /* Height of the scrollbar */
        },

        /* Customize the scrollbar track (background) */
        "*::-webkit-scrollbar-track": {
          background: "transparent", /* Background color of the track */
        },

        /* Customize the scrollbar thumb (the draggable part) */
        "*::-webkit-scrollbar-thumb": {
          background: colors.primaryLight, /* Color of the thumb */
          borderRadius: "5px", /* Rounded corners of the thumb */
        },

        /* Change scrollbar on hover */
        "*::-webkit-scrollbar-thumb:hover": {
          background: colors.primaryDark, /* Color of the thumb on hover */
        },
        html: {
          height: "100%",
          boxSizing: "border-box",
        },
        body: {
          margin: 0,
          padding: 0,
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: colors.backgroundDefault, // Set the text color to white
        },
        root: {
          // Override the default text-transform
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white', // Set background color to white
          color: 'grey', // Change text color
        }
      }
    }
  },
  typography: {
    fontFamily: "Montserrat, Roboto, Arial, sans-serif",
    h1: {
      // Display Large
      fontSize: 57,
      lineHeight: 1.12, // 64px/57px
      letterSpacing: "-0.025em",
    },
    h2: {
      // Display Medium
      fontSize: 45,
      lineHeight: 1.15, // 52px/45px
      letterSpacing: "0em",
    },
    h3: {
      // Display Small
      fontSize: 36,
      lineHeight: 1.22, // 44px/36px
      letterSpacing: "0em",
    },
    h4: {
      // Headline Large
      fontSize: 32,
      lineHeight: 1.25, // 40px/32px
      letterSpacing: "0em",
    },
    h5: {
      // Headline Medium
      fontSize: 28,
      lineHeight: 1.14, // 36px/28px
      letterSpacing: "0em",
    },
    h6: {
      // Headline Small
      fontSize: 24,
      lineHeight: 1.33, // 32px/24px
      letterSpacing: "0em",
    },
    subtitle1: {
      // Title Large
      fontSize: 22,
      lineHeight: 1.27, // 28px/22px
      letterSpacing: "0em",
    },
    subtitle2: {
      // Title Medium
      fontSize: 16,
      lineHeight: 1.4,
      letterSpacing: "0.15px",
    },
    body1: {
      // Body Large
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: "0.5px",
    },
    body2: {
      // Body Medium
      fontSize: 14,
      lineHeight: 1.42,
      letterSpacing: "0.25px",
    },
  },
  palette: {
    primary: {
      main: colors.primaryLight,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondaryMain,
    },
    background: {
      paper: colors.backgroundPaper,
      default: colors.backgroundDefault,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    divider: colors.divider,
    grey: {
      dark: colors.greyDark,
      light: colors.greyLight,
    },
  },
});

export default theme;
