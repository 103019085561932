import React, { useState, useEffect } from 'react';
import { Box, Paper, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { AccountCircle, Security, VpnKey, NoAccountsOutlined } from '@mui/icons-material';

import ProfileSettings from '../../components/forms/profileSettings/profileSettings';
import SecuritySettings from '../../components/forms/profileSettings/securitySettings';
import LicenseSettings from '../../components/forms/profileSettings/licenseSettings';
import DeleteAccount from '../../components/forms/profileSettings/deleteAccount';

import bgHorizontal from '../../assets/media/backgrounds/bgHorizontal.jpg';
import authService from '../../services/authService';

const Profile = () => {
    const { t } = useTranslation();
    const [profileInformation, setProfileInformation] = useState({
        first_name: '',
        last_name: '',
        position: '',
        company: '',
        sex: '',
    });
    const [loading, setLoading] = useState(true);
    
    const [currentView, setCurrentView] = useState('profileInformation');

    useEffect(() => {
        const fetchProfileAbout = async () => {
            const response = await authService.profileAbout();
            if (response.success) {
                setProfileInformation(response.data);
            }
            setLoading(false);
        }
        fetchProfileAbout();
    }, []);

    const renderContent = () => {
        switch (currentView) {
            case 'profileInformation':
                return <ProfileSettings />;
            case 'securitySettings':
                return <SecuritySettings />;
            case 'licenseSettings':
                return <LicenseSettings />;
            case 'deleteAccount':
                return <DeleteAccount />;
            default:
                return <ProfileSettings />;
        }
    };

    if (loading) return null;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                    },
                    backgroundImage: `url(${bgHorizontal})`,
                    top: 'auto',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100vw',
                    height: '250px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* Profile small details box */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        padding: '20px',
                        borderRadius: '10px',
                        color: 'white',
                    }}
                >
                    <h1>{profileInformation.first_name} {profileInformation.last_name}</h1>
                    <p>{profileInformation.position} @ {profileInformation.company}</p>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row'},
                    minHeight: 'calc(100% - 290px)',
                    width: { xs: '100%', md: '75%' },
                    mx: 'auto',
                    my: '20px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
            >
                <Paper sx={{ width: { xs: '100%', md: "auto" } }}>
                    <MenuList
                        sx={{
                            '& .MuiTypography-body1': {
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: "darkslategray"
                            },
                            // style MenuItem conatiner
                            '& .MuiMenuItem-root': {
                                padding: '15px 25px'
                            }, 

                        }}
                    >
                        <MenuItem
                            onClick={() => setCurrentView('profileInformation')}
                        >
                            <ListItemIcon>
                                <AccountCircle />
                            </ListItemIcon>
                            <ListItemText>{t("profileSettings.profileInformation.title")}</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => setCurrentView('securitySettings')}
                        >
                            <ListItemIcon>
                                <Security />
                            </ListItemIcon>
                            <ListItemText>{t("profileSettings.securitySettings.title")}</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => setCurrentView('licenseSettings')}
                        >
                            <ListItemIcon>
                                <VpnKey />
                            </ListItemIcon>
                            <ListItemText>{t("profileSettings.licenseSettings.title")}</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => setCurrentView('deleteAccount')}
                        >
                            <ListItemIcon>
                                <NoAccountsOutlined />
                            </ListItemIcon>
                            <ListItemText>{t("profileSettings.deleteAccount.title")}</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
                <Box sx={{
                    backgroundColor: 'whitesmoke',
                    padding: '20px',
                    width: { xs: '100%', md: "calc(100% - 320px)" },
                }}>
                    {renderContent()}
                </Box>
            </Box>

        </Box>
    );
};


export default Profile;
