/**
 * Handles a request and returns the response.
 * @param {Promise} request - The request to be handled.
 * @returns {Promise} - A promise that resolves to an object with success and data properties if the request is successful,
 *                      or an object with success and error properties if the request fails.
 */
const handleRequest = async (request) => {
    try {
      const response = await request;
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error.response ? error.response.data : 'Network Error' };
    }
  };

export default handleRequest;