import React, { useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authProvider";

const LogoutPage = () => {
  const { setCurrentUser } = useContext(AuthContext);

  // clear localstorage jwt tokens, null user and redirect to "/"
  useEffect(() => {
    localStorage.removeItem("jwt-access");
    localStorage.removeItem("jwt-refresh");
    setCurrentUser(null);
  }, []);

  return <Navigate to="/" />;
};

export default LogoutPage;
