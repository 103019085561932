import React from 'react';
import {useField, useFormikContext} from 'formik';
import {Autocomplete, TextField} from '@mui/material';

import {countryChoices as options} from "../../../utilities/choices";


const CountrySelect = ({name, label}) => {
    const {setFieldValue, setFieldTouched} = useFormikContext();
    const [field, meta, helpers] = useField(name);

    const handleChange = (_, value) => {
        setFieldValue(name, value ? value.value : '');
        helpers.setTouched(true);
    };

    const handleBlur = () => {
        setFieldTouched(name, true);
    };

    return (
        <Autocomplete
            id={name}
            options={options}
            getOptionLabel={(option) => option.label}
            value={options.find(option => option.value === field.value) || null}
            onChange={handleChange}
            onBlur={handleBlur}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    fullWidth
                />
            )}
        />
    );
};

export default CountrySelect;