import * as Yup from "yup";

import i18n from "../services/i18n";

// Translation function
const t = (key) => i18n.t(key);

// UUID regex for validating license key
const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

// Phone number (Georgia) regex
const phoneRegex = /^5\d{8}$/;

const licenseValidator = Yup.string()
    .matches(uuidRegex, t("validation.provideValidLicense"))
    .required(t("validation.provideLicense"));

// Schemas
export const licenseValidationSchema = Yup.object().shape({
    license: licenseValidator,
});

export const registerPageValidationSchema = Yup.object().shape({
    // Stage 1: License
    license: licenseValidator,

    // Stage 2: Basic Information
    firstName: Yup.string().required(t("validation.provideFirstName")),
    lastName: Yup.string().required(t("validation.provideLastName")),

    email: Yup.string()
        .email(t("validation.provideValidEmail"))
        .required(t("validation.provideEmail")),
    phone: Yup.string()
        .matches(phoneRegex, t("validation.provideValidPhone"))
        .required(t("validation.providePhone")),

    password: Yup.string()
        .min(8, t("validation.passwordTooShort"))
        .required(t("validation.providePassword")),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], t("validation.passwordsDoNotMatch"))
        .required(t("validation.confirmPassword")),

    // Stage 3: Personal Details
    birthYear: Yup.number()
        .required(t("validation.provideBirthYear"))
        .min(1900, t("validation.provideValidBirthYear"))
        .max(new Date().getFullYear() - 18, t("validation.youMustBe18")),

    birthMonth: Yup.number()
        .required(t("validation.provideBirthMonth"))
        .min(1, t("validation.provideValidBirthMonth"))
        .max(12, t("validation.provideValidBirthMonth")),

    birthDay: Yup.number()
        .required(t("validation.provideBirthDay"))
        .min(1, t("validation.provideValidBirthDay"))
        .max(31, t("validation.provideValidBirthDay")),

    sex: Yup.string().required(t("validation.chooseGender")),

    countryOfResidence: Yup.string().required(t("validation.provideCountryOfResidence")),
    countryOfCitizenship: Yup.string().required(t("validation.provideCountryOfCitizenship")),

    company: Yup.string().required(t("validation.provideCompany")),
    industry: Yup.string().required(t("validation.provideIndustry")),
    position: Yup.string().required(t("validation.providePosition")),
});


export const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email(t("validation.provideValidEmail"))
        .required(t("validation.provideEmail")),
    password: Yup.string().required(t("validation.providePassword")),
});


export const requestResetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email(t("validation.provideValidEmail"))
        .required(t("validation.provideEmail")),
});

export const passwordResetConfirmValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, t("validation.passwordTooShort"))
        .required(t("validation.providePassword")),
    reNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], t("validation.passwordsDoNotMatch"))
        .required(t("validation.confirmPassword")),
});


export const profileSettingsPasswordChangeValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t("validation.provideCurrentPassword")),
    newPassword: Yup.string()
        .min(8, t("validation.passwordTooShort"))
        .required(t("validation.providePassword")),
    reNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], t("validation.passwordsDoNotMatch"))
        .required(t("validation.confirmPassword"))
});

export const profileSettingsDeleteUserValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t("validation.providePassword")),
});

export const linkSearchValidationSchema = Yup.object().shape({
    url: Yup.string()
        .required("URL is required")
        .url("Invalid URL format")
});


export const filterValidationSchema = Yup.object({
    priceRange: Yup.array()
        .of(Yup.number().nullable().min(0)) // Example rule
        .test(
            'price-range',
            'Max price must be greater than min price.',
            (values) => !values[0] || !values[1] || values[0] <= values[1]
        )
        .required('Price range is required'),
    areaRange: Yup.array()
        .of(Yup.number().nullable().min(0))
        .test(
            'area-range',
            'Max area must be greater than min area.',
            (values) => !values[0] || !values[1] || values[0] <= values[1]
        )
        .required('Area range is required'),
    selectedPropertyTypes: Yup.array()
        .of(Yup.string())
        .min(1, 'At least one property type must be selected')
        .required('At least one property type must be selected'),
    selectedTransactionTypes: Yup.array()
        .of(Yup.string())
        .min(1, 'At least one transaction type must be selected')
        .required('At least one transaction type must be selected'),
});