import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CountrySelect from '../fields/CountrySelect';
import { useTranslation } from 'react-i18next';
import authService from '../../../services/authService';
import SubmitButton from "../fields/submitButton";

const ProfileSettings = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
        first_name: '',
        last_name: '',
        company: '',
        industry: '',
        position: '',
        citizenship: '',
        residence: ''
    });

    useEffect(() => {
        // Fetch profile data from API
        const fetchProfileDetails = async () => {
            const response = await authService.profileDetails();
            if (response.success) {
                setInitialValues(response.data);
            }
            setLoading(false);
        };
        fetchProfileDetails();
    }, []);

    const validationSchema = Yup.object({
        first_name: Yup.string().required(t("validation.required")),
        last_name: Yup.string().required(t("validation.required")),
        company: Yup.string().required(t("validation.required")),
        industry: Yup.string().required(t("validation.required")),
        position: Yup.string().required(t("validation.required")),
        citizenship: Yup.string().required(t("validation.required")),
        residence: Yup.string().required(t("validation.required")),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await authService.updateProfile(values);
            if (response.success) {
                window.location.reload();
            } else {
                // handle error
            }
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
        setSubmitting(false);
    };

    if (loading) return null;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, handleChange, handleBlur, touched, errors, isSubmitting }) => (
                <Form>
                    <h2>{t("profileSettings.profileInformation.title")}</h2>
                    <p>{t("profileSettings.profileInformation.description")}</p>
                    <Box
                        sx={{
                            mt: 2,
                            p: 2,
                            width: { xs: '100%', sm: '600px' },
                            maxWidth: '100%',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label={t("profileSettings.profileInformation.fields.firstName")}
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.first_name && Boolean(errors.first_name)}
                                    helperText={touched.first_name && errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label={t("profileSettings.profileInformation.fields.lastName")}
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.last_name && Boolean(errors.last_name)}
                                    helperText={touched.last_name && errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    id="company"
                                    name="company"
                                    label={t("profileSettings.profileInformation.fields.company")}
                                    value={values.company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.company && Boolean(errors.company)}
                                    helperText={touched.company && errors.company}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    id="industry"
                                    name="industry"
                                    label={t("profileSettings.profileInformation.fields.industry")}
                                    value={values.industry}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.industry && Boolean(errors.industry)}
                                    helperText={touched.industry && errors.industry}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    id="position"
                                    name="position"
                                    label={t("profileSettings.profileInformation.fields.position")}
                                    value={values.position}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.position && Boolean(errors.position)}
                                    helperText={touched.position && errors.position}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CountrySelect
                                    name="citizenship"
                                    label={t("profileSettings.profileInformation.fields.countryOfCitizenship")}
                                    value={values.citizenship}
                                    onChange={handleChange}
                                    error={touched.citizenship && errors.citizenship}
                                    helperText={touched.citizenship && errors.citizenship}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CountrySelect
                                    name="residence"
                                    label={t("profileSettings.profileInformation.fields.countryOfResidence")}
                                    value={values.residence}
                                    onChange={handleChange}
                                    error={touched.residence && errors.residence}
                                    helperText={touched.residence && errors.residence}
                                />
                            </Grid>
                        </Grid>
                        <SubmitButton
                            sx={{ mt: 2 }}
                        >
                            {t("profileSettings.profileInformation.update")}
                        </SubmitButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileSettings;
