import React from "react";
import { Typography } from "@mui/material";

const PrivacyPolicy = (
  <>
    <Typography variant="h6" gutterBottom>
      Privacy Policy
    </Typography>
    <Typography variant="body1" paragraph>
      This Privacy Policy describes how EstReal ("we", "us," or "our") collects,
      uses, and discloses information when you use our website, EstReal.ge
      ("Website"). By accessing or using our Website, you agree to the terms of
      this Privacy Policy.
    </Typography>
    <Typography variant="h6" gutterBottom>
      1. Information We Collect
    </Typography>
    <Typography variant="body1" paragraph>
      1.1. Personal Information: When you use our Website, we may collect
      personal information that you voluntarily provide to us, such as your
      name, email address, phone number, and any other information you choose to
      provide.
    </Typography>
    <Typography variant="body1" paragraph>
      1.2. Usage Information: We may also collect information about your use of
      the Website, including your IP address, browser type, operating system,
      pages visited, and other usage statistics.
    </Typography>
    <Typography variant="h6" gutterBottom>
      2. Use of Information
    </Typography>
    <Typography variant="body1" paragraph>
      2.1. We may use the information we collect for various purposes, including
      to:
      <br />
      - Provide and maintain our Website;
      <br />
      - Process your requests and transactions;
      <br />
      - Communicate with you about our services;
      <br />
      - Personalize your experience on our Website;
      <br />
      - Analyze and improve our services;
      <br />
      - Detect, prevent, and address technical issues; and
      <br />- Comply with legal obligations.
    </Typography>
    <Typography variant="h6" gutterBottom>
      3. Disclosure of Information
    </Typography>
    <Typography variant="body1" paragraph>
      3.1. We may share your information with third parties in the following
      circumstances: With service providers who assist us in operating our
      Website or providing services to you; To comply with legal obligations or
      to protect our rights or the rights of others; and With your consent or as
      otherwise disclosed at the time of collection.
    </Typography>
    <Typography variant="h6" gutterBottom>
      4. Data Security
    </Typography>
    <Typography variant="body1" paragraph>
      4.1. We take reasonable measures to protect the security of your
      information and to prevent unauthorized access, disclosure, alteration, or
      destruction.
    </Typography>
    <Typography variant="h6" gutterBottom>
      5. Third-Party Links
    </Typography>
    <Typography variant="body1" paragraph>
      5.1. Our Website may contain links to third-party websites or services
      that are not owned or controlled by EstReal. This Privacy Policy does not
      apply to those third-party websites or services, and we are not
      responsible for their privacy practices. We encourage you to review the
      privacy policies of those third parties before providing any information
      to them.
    </Typography>
    <Typography variant="h6" gutterBottom>
      6. Children's Privacy
    </Typography>
    <Typography variant="body1" paragraph>
      6.1. Our Website is not directed to children under the age of 18, and we
      do not knowingly collect personal information from children under the age
      of 18. If you are a parent or guardian and believe that your child has
      provided us with personal information, please contact us so that we can
      delete the information.
    </Typography>
    <Typography variant="h6" gutterBottom>
      7. Changes to this Privacy Policy
    </Typography>
    <Typography variant="body1" paragraph>
      7.1. We may update this Privacy Policy from time to time. Any changes will
      be effective when we post the revised Privacy Policy on our Website. We
      encourage you to review this Privacy Policy periodically for any changes.
    </Typography>
  </>
);


export default PrivacyPolicy;