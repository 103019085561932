import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import authService from '../../../services/authService';
import { profileSettingsPasswordChangeValidationSchema } from '../../../utilities/validationSchemas';

import SubmitButton from '../fields/submitButton';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const SecuritySettings = () => {
    const { t } = useTranslation();

    const [contactInformation, setContactInformation] = useState({
        email: '',
        phone: ''
    });

    useEffect(() => {
        const fetchContactInformation = async () => {
            const response = await authService.profileContactInformation();
            if (response.success) {
                setContactInformation(response.data);
            }
        };
        fetchContactInformation();
    }, []);

    const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
        const response = await authService.changePassword(values);
        if (response.success) {
            window.location.reload();
        } else {
            // Display error messages
            if (response.error?.current_password) setFieldError('currentPassword', t(response.error.current_password[0]));
            if (response.error?.new_password) setFieldError('newPassword', t(response.error.new_password[0]));
            if (response.error?.re_new_password) setFieldError('reNewPassword', t(response.error.re_new_password[0]));
        }

        setSubmitting(false);
    };

    return (
        <Box>
            <h2>{t("profileSettings.securitySettings.title")}</h2>
            <p>{t("profileSettings.securitySettings.description")}</p>

            <Typography variant="h6" my={3}>{t("profileSettings.securitySettings.contactInformation")}</Typography>

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: { xs: 'column', sm: 'row' },
                }}
            >
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={contactInformation?.email}
                    type="email"
                    label={t("profileSettings.securitySettings.fields.email")}
                    disabled
                />

                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={contactInformation?.phone}
                    type="text"
                    label={t("profileSettings.securitySettings.fields.phone")}
                    disabled
                />
            </Box>

            <Typography variant="h6" my={3}>{t("profileSettings.securitySettings.changePassword")}</Typography>

            <Formik
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    reNewPassword: ''
                }}
                validationSchema={profileSettingsPasswordChangeValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, touched, errors }) => (
                    <Form>
                        <Box
                            sx={{
                                maxWidth: "100%",
                                width: { xs: '100%', sm: '600px' }
                            }}
                        >
                            <Box marginBottom={2}>
                                <Field
                                    as={TextField}
                                    type="password"
                                    name="currentPassword"
                                    label={t("profileSettings.securitySettings.fields.currentPassword")}
                                    variant="outlined"
                                    error={touched.currentPassword && !!errors.currentPassword}
                                    helperText={touched.currentPassword && errors.currentPassword}
                                    fullWidth
                                />
                            </Box>

                            <Box marginBottom={2}>
                                <Field
                                    as={TextField}
                                    type="password"
                                    name="newPassword"
                                    label={t("profileSettings.securitySettings.fields.newPassword")}
                                    variant="outlined"
                                    error={touched.newPassword && !!errors.newPassword}
                                    helperText={touched.newPassword && errors.newPassword}
                                    fullWidth
                                />
                            </Box>

                            <Box marginBottom={2}>
                                <Field
                                    as={TextField}
                                    type="password"
                                    name="reNewPassword"
                                    label={t("profileSettings.securitySettings.fields.reNewPassword")}
                                    variant="outlined"
                                    error={touched.reNewPassword && !!errors.reNewPassword}
                                    helperText={touched.reNewPassword && errors.reNewPassword}
                                    fullWidth
                                />
                            </Box>

                            <SubmitButton
                                disabled={isSubmitting}
                                type="submit"
                            >
                                {t("profileSettings.securitySettings.update")}
                            </SubmitButton>
                        </Box>
                    </Form>
                )
                }
            </Formik >
        </Box >
    );
};

export default SecuritySettings;