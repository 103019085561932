export const flattenErrors = (errors, parentKey = "") => {
    let flatErrors = {};
    for (let key in errors) {
        let newKey = parentKey ? `${parentKey}_${key}` : key;
        if (Array.isArray(errors[key])) {
            flatErrors[newKey] = errors[key][0];
        } else if (typeof errors[key] === "object") {
            Object.assign(flatErrors, flattenErrors(errors[key], newKey));
        } else {
            flatErrors[newKey] = errors[key];
        }
    }
    return flatErrors;
};

const apiToFrontendRepresentation = {
    email: "email",
    phone: "phone",
    password: "password",
    re_password: "confirmPassword",
    license: "license",
    first_name: "firstName",
    last_name: "lastName",
    company: "company",
    industry: "industry",
    position: "position",
    citizenship: "countryOfCitizenship",
    residence: "countryOfResidence",
    sex: "sex",
    date_of_birth: "birthYear", // This is a date string in the format "YYYY-MM-DD", so we show error on year field
};

const stageOneFields = ["license"];
const stageTwoFields = ["firstName", "lastName", "email", "phone", "password", "confirmPassword"];

export const parseErrors = (errors) => {
    if ("profile" in errors) {
        const profileErrors = errors.profile;
        delete errors.profile;
        Object.assign(errors, profileErrors);
    }

    let parsedErrors = {};
    for (let [key, value] of Object.entries(errors)) {
        const frontendReprKey = apiToFrontendRepresentation[key]
        if (frontendReprKey) {
            const errorString = errors[key].join(". ");
            parsedErrors[frontendReprKey] = errorString;
        }
    }

    const stepToJump = 
    (stageOneFields.some(field => field in parsedErrors)) 
        ? 1
        : (stageTwoFields.some(field => field in parsedErrors)) 
            ? 2 
            : 3;

    return [parsedErrors, stepToJump];
}