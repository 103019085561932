import { useState, useEffect, useContext, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { toast } from "react-toastify";

import contentService from "../../../services/contentService";
import { FilterContext } from '../mapBox';

const MarkerClusters = () => {
    // state for storing fetched tiles and clusters
    const [clusters, setClusters] = useState([]);
    const { filters } = useContext(FilterContext);

    const map = useMap();
    const markerLayerRef = useRef(null);

    const fetchMarkerClusters = async () => {
        const response = await contentService.getClusters(map.getZoom(), filters);
        if (response && response.data && response.data.length > 0) {
            setClusters(response.data);
        } else {
            setClusters([]);
            toast.error("No listings found for the selected filters!");
        }
    }

    useEffect(() => {
        if (!markerLayerRef.current) {
            markerLayerRef.current = L.layerGroup().addTo(map);
        } else {
            markerLayerRef.current.clearLayers();
        }

        clusters.forEach(m => {
            const marker = L.marker(
                [m.centroid[1], m.centroid[0]], {
                    icon: L.divIcon({
                        className: 'marker-cluster',
                        html: `<div>${new Intl.NumberFormat('en-US').format(m.avg_price)}$</div>`,
                        iconSize: [100, 32],
                        iconAnchor: [50, 16],
                    })
                }
            );

            marker.on('click', () => {
                map.setView([m.centroid[1], m.centroid[0]], map.getZoom() + 1);
            });

            markerLayerRef.current.addLayer(marker);
        });

        return () => {
            markerLayerRef.current.clearLayers();
        }
    }, [clusters]);


    useEffect(() => {
        fetchMarkerClusters();
    }, [map.getZoom(), filters]);

    return null;
};

export default MarkerClusters;
