export const parseFilterParams = (filters) => {
    const params = {};

    // Handle price range
    if (filters.priceRange[0] != null) {
        params.priceFrom = filters.priceRange[0];
    }
    if (filters.priceRange[1] != null) {
        params.priceTo = filters.priceRange[1];
    }

    // Handle area range
    if (filters.areaRange[0] != null) {
        params.areaFrom = filters.areaRange[0];
    }
    if (filters.areaRange[1] != null) {
        params.areaTo = filters.areaRange[1];
    }

    // Handle selected property types
    if (filters.selectedPropertyTypes.length > 0) {
        params.propertyTypes = filters.selectedPropertyTypes.join(',');
    }

    // Handle selected transaction types
    if (filters.selectedTransactionTypes.length > 0) {
        params.transactionTypes = filters.selectedTransactionTypes.join(',');
    }

    return params;  // Return as a simple object
}
