import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, InputAdornment } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import authService from '../../../services/authService';
import { licenseValidationSchema } from '../../../utilities/validationSchemas';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SubmitButton from '../fields/submitButton';


const LicenseSettings = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [showKey, setShowKey] = useState(false);
    const [licenseData, setLicenseData] = useState(null);

    const toggleShowKey = () => {
        setShowKey(!showKey);
    };

    useEffect(() => {
        const fetchLicenseData = async () => {
            const response = await authService.licenseKeyDetails();

            if (response.success) {
                setLicenseData(response.data);
            }
            setLoading(false);
        };
        fetchLicenseData();
    }, []);



    const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
        const response = await authService.renewLicenseKey(values.license);
        if (response.success) {
            window.location.reload();
        } else {
            // Display error messages
            if (response.error?.license_key) setFieldError('license', t(response.error.license_key[0]));
        }
    };

    if (loading) return null;

    return (
        <Box>
            <h2>{t("profileSettings.licenseSettings.title")}</h2>
            <p>{t("profileSettings.licenseSettings.description")}</p>

            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("profileSettings.licenseSettings.fields.license")}</Typography>

                        <Typography variant="body1" fontWeight="bold" color={licenseData.is_active ? 'success.main' : 'error.main'}>
                            {licenseData.is_active ? t("profileSettings.licenseSettings.active") : t("profileSettings.licenseSettings.inactive")}
                        </Typography>

                        <Typography variant="body1">
                            <Button onClick={toggleShowKey} sx={{ mr: 2 }}>
                                {showKey ? t("profileSettings.licenseSettings.hide") : t("profileSettings.licenseSettings.show")}
                            </Button>
                            {showKey ? licenseData.key : "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("profileSettings.licenseSettings.fields.usage")}</Typography>
                        <Typography variant="body1" fontSize="1.2em" fontWeight="bold">{licenseData.users_count} / {licenseData.max_users}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("profileSettings.licenseSettings.fields.expirationDate")}</Typography>
                        <Typography variant="body1" fontSize="1.2em" fontWeight="bold">{licenseData.expiration_date}</Typography>
                    </Grid>

                    <Grid item xs={9}>
                        <Typography variant="h6" mb={3}>{t("profileSettings.licenseSettings.renewLicense")}</Typography>
                        <Formik
                            initialValues={{ license: '' }}
                            validationSchema={licenseValidationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange, handleBlur, touched, errors, isSubmitting }) => (
                                <Form>
                                    <Field
                                        as={TextField}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VpnKeyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        name="license"
                                        type="text"
                                        label={t("profileSettings.licenseSettings.fields.license")}
                                        error={touched.license && Boolean(errors.license)}
                                        helperText={touched.license && errors.license}
                                        fullWidth
                                    />
                                    <Box mt={2}>
                                        <SubmitButton
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {t("profileSettings.licenseSettings.renew")}
                                        </SubmitButton>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default LicenseSettings;

