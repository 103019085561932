import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Container,
  Typography
} from "@mui/material";

import bgHorizontal from "../../assets/media/backgrounds/bgHorizontal.jpg";
import bgVertical from "../../assets/media/backgrounds/bgVertical.jpg";

import PrivacyPolicy from "../../components/textual/privacyPolicy";
import TermsAndConditions from "../../components/textual/termsAndConditions";

const TermsAndPrivacy = () => {
  const [activeSection, setActiveSection] = useState("privacy");

  return (
    <Box
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        },
        backgroundImage: { xs: `url(${bgVertical})`, md: `url(${bgHorizontal})` },
        top: "auto",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container
        component={Paper}
        elevation={3}
        sx={{
          my: 2,
          zIndex: 2,
          display: "flex",
          overflow: "auto",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: "90%",
        }}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: { staggerChildren: 0.05 },
          },
        }}
        initial="hidden"
        animate="visible"
      >
        <Box width="100%" display="flex" justifyContent="center" gap={2} p={4}>
          <Button
            variant={activeSection === "privacy" ? "contained" : "outlined"}
            onClick={() => setActiveSection("privacy")}
          >
            Privacy Policy
          </Button>
          <Button
            variant={activeSection === "terms" ? "contained" : "outlined"}
            onClick={() => setActiveSection("terms")}
          >
            Terms and Conditions
          </Button>
        </Box>

        {/* TEMPORARY NOTICE */}
        <Typography variant="body" fontWeight="bold" gutterBottom>
            IMPORTANT: This is a placeholder for the Terms and Conditions and Privacy Policy.
        </Typography>

        <Box sx={{ width: "100%" }}>
          {activeSection === "privacy" ? PrivacyPolicy : TermsAndConditions}
        </Box>
      </Container>
    </Box>
  );
};

export default TermsAndPrivacy;
