import React from 'react';
import Box from '@mui/material/Box';

const Layout = ({ children }) => {
    // Assuming the first child is NavBar and the second is Routes Component
    const [NavBar, Routes] = React.Children.toArray(children);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh', // Full viewport height
            width: '100vw' // Full viewport width
        }}>
            <Box sx={{ width: '100%', flexGrow: 0 }}>
                {NavBar}
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                {Routes}
            </Box>
        </Box>
    );
}

export default Layout;
