import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { Stepper, Step, StepLabel, Box, Typography, IconButton, Link as MuiLink } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

import { parseErrors, flattenErrors} from "../../utilities/errorHandlers";
import authService from "../../services/authService";
import { registerPageValidationSchema } from "../../utilities/validationSchemas";
import GenericFormContainer from "../../components/forms/genericFormContainer";

import LicenseFormStage from "../../components/forms/registration/licenseFormStage";
import BasicInformationFormStage from "../../components/forms/registration/basicInformationFormStage";
import PersonalDetailsFormStage from "../../components/forms/registration/personalDetailsFormStage";

import SubmitButton from "../../components/forms/fields/submitButton";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RegisterPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [isForwardEnabled, setIsForwardEnabled] = useState(false);    // Disable the forward button as we need to check the license key first

    const initialValues = {
        license: "",

        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",

        countryOfResidence: "GE",
        countryOfCitizenship: "GE",
        birthYear: new Date().getFullYear() - 18, // Default to 18 years ago
        birthMonth: 1, // Default to January
        birthDay: 1, // Default to the first day of the month
        company: "",
        industry: "",
        position: "",
        sex: "",
    };

    const steps = [
        t("registerPage.stages.license"),
        t("registerPage.stages.basicInformation"),
        t("registerPage.stages.personalDetails"),
    ];

    const handleNextStep = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSubmit = async (values, { setFieldError }) => {
        console.log(values);
        const response = await authService.register(values);

        if (response.success) {
            // If the register request was successful, redirect to the email verify page
            navigate("/notify-email-verify");
            return;
        }

        // if the register request was not successful, set field errors
        const [errors, stepToJump] = parseErrors(response.error);

        Object.keys(errors).forEach((key) => {
            setFieldError(key, errors[key]);
        });

        // Jump to the step where the first error occurred
        setCurrentStep(stepToJump);
    };

    return (
        <GenericFormContainer>
            <Box
                sx={{
                    position: "relative",   // Allows the forward/backward buttons to be positioned absolutely
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    pb: "5rem", // Padding bottom to allow space for the forward/backward buttons
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        marginBottom: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h1"
                        color="grey.dark"
                        sx={{
                            fontWeight: "bold",
                            fontSize: {
                                xs: "1.5rem",
                                sm: "2.25rem",
                            },
                        }}
                    >
                        {t("registerPage.signUp")}
                    </Typography>

                    <Typography variant="body1" textAlign="center" fontWeight="bold">
                        {t("registerPage.alreadyHaveAccount")}
                        <MuiLink
                            component={Link}
                            to="/login"
                            sx={{
                                border: "2px solid",
                                padding: "0.25em .75em",
                                borderRadius: "10px",
                                ml: 0.5,
                                color: "grey.dark",
                                textDecoration: "none",
                            }}
                        >
                            {t("registerPage.login")}
                        </MuiLink>
                    </Typography>
                </Box>

                <Stepper sx={{mt: 3, mb: 4}} activeStep={currentStep - 1} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Formik
                    initialValues={initialValues}
                    validationSchema={registerPageValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            {/* Render all forms but only display the active one */}
                            <Box>
                                <Box sx={{ display: currentStep === 1 ? 'block' : 'none' }}>
                                    <LicenseFormStage setIsForwardEnabled={setIsForwardEnabled} setCurrentStep={setCurrentStep} />
                                </Box>
                                <Box sx={{ display: currentStep === 2 ? 'block' : 'none' }}>
                                    <BasicInformationFormStage />
                                </Box>
                                <Box sx={{ display: currentStep === 3 ? 'block' : 'none' }}>
                                    <PersonalDetailsFormStage />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mt: 4,
                                    position: "absolute",
                                    bottom: 0,
                                }}
                            >
                                <IconButton
                                    variant="outlined"
                                    color="primary"
                                    onClick={handlePrevStep}
                                    disabled={currentStep === 1}
                                    sx={{
                                        borderRadius: '50%', // Makes the button perfectly round
                                        width: 56, // Sets the width to 56 pixels
                                        height: 56, // Sets the height to 56 pixels
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'primary.main',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>

                                {/* Submit button */}
                                {currentStep === steps.length && (
                                    <SubmitButton sx={{ width: "50%" }}>
                                        {t("registerPage.submit")}
                                    </SubmitButton>
                                )}

                                <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNextStep}
                                    disabled={currentStep === steps.length || !isForwardEnabled}
                                    sx={{
                                        borderRadius: '50%', // Makes the button perfectly round
                                        width: 56, // Sets the width to 56 pixels
                                        height: 56, // Sets the height to 56 pixels
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'primary.main',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>

                        </Form>
                    )}
                </Formik>
            </Box>
        </GenericFormContainer>
    );
};

export default RegisterPage;
