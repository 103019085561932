import { useEffect, useRef, useContext } from "react";
import ReactDOM from 'react-dom';
import ngeohash from "ngeohash";
import L from "leaflet";
import { useMap } from "react-leaflet";
import { toast } from "react-toastify";
import { debounce } from "lodash";

import { FilterContext } from "../mapBox";
import MarkerPopup from "./markerPopup";
import contentService from "../../../services/contentService";
import { calculateColor } from "../../../utilities/colors";

const MarkerTiles = () => {
    const layersRef = useRef({});
    const map = useMap();
    const { filters } = useContext(FilterContext);

    const popup = L.popup();

    function updatePopupContent(listing) {
        const container = document.createElement('div');
        popup.setContent(container);
        ReactDOM.render(<MarkerPopup listing={listing} />, container);
    
        // Make sure to cleanup the React component when the popup closes
        popup.once('remove', () => {
            ReactDOM.unmountComponentAtNode(container);
        });
    }

    const calculateVisibleGeohashes = () => {
        const bounds = map.getBounds();
        const precision = 6;
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        return ngeohash.bboxes(sw.lat, sw.lng, ne.lat, ne.lng, precision);
    };
    
    

    const getShapeDiv = (propertyType, priceColor) => {
        switch (propertyType) {
            case "APT":
                return `<div class="triangle" style="color: ${priceColor};"></div>`;
            case "HSE":
                return `<div class="rotatedTriangle" style="color: ${priceColor};"></div>`;
            case "LND":
                return `<div class="circle" style="background-color: ${priceColor};"></div>`;
            case "COM":
                return `<div class="square" style="background-color: ${priceColor};"></div>`;
            case "HOT":
                return `<div class="rotatedSquare" style="background-color: ${priceColor};"></div>`;
            default:
                return `<div style="background-color: ${priceColor};"></div>`;

        }
    }


    const manageLayers = (tilesData) => {
        const visibleGeohashes = calculateVisibleGeohashes();

        // Remove layers that are no longer visible
        Object.keys(layersRef.current).forEach((geohash) => {
            if (!visibleGeohashes.includes(geohash)) {
                map.removeLayer(layersRef.current[geohash]);
                delete layersRef.current[geohash];
            }
        });

        // Add new layers for newly visible geohashes
        visibleGeohashes.forEach((geohash) => {
            if (!layersRef.current[geohash] && tilesData[geohash]) {
                const layer = L.layerGroup();
                tilesData[geohash].forEach((marker) => {
                    const backgroundColor = calculateColor(marker.price, marker.objective_price);
                    const formattedPrice = new Intl.NumberFormat("en-US").format(marker.price);
                    const propertyType = marker.redcat_id.split("-")[2];

                
                    const leafletMarker = L.marker(
                        [marker.location[1], marker.location[0]],
                        {
                            icon: L.divIcon({
                                className: "marker-element-container",
                                html: getShapeDiv(propertyType, backgroundColor),
                                iconSize: [20, 20],
                                iconAnchor: [10, 10],
                            }),
                        }
                    );

                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        leafletMarker.on('click', () => {
                            updatePopupContent(marker);
                            leafletMarker.bindPopup(popup).openPopup();
                        });
                    } else {
                        // Bind hover event to the marker to update and show the popup
                        leafletMarker.on('mouseover', () => {
                            updatePopupContent(marker);
                            leafletMarker.bindPopup(popup).openPopup();
                        });
                    }
    

                    layer.addLayer(leafletMarker);
                });
                layer.addTo(map);
                layersRef.current[geohash] = layer;
            }
        });
    };

    const fetchMarkerTiles = async () => {
        if (map.getZoom() <= 16) return;
        const visibleGeohashes = calculateVisibleGeohashes();
        const tilesToLoad = visibleGeohashes.filter(
            (geohash) => !layersRef.current.hasOwnProperty(geohash)
        );

        if (tilesToLoad.length === 0) {
            return;
        }

        try {
            const response = await contentService.getTiles(tilesToLoad, filters);
            if (response && response.data) {
                manageLayers(response.data);
            } else {
                toast.error("Could not fetch marker tiles!");
            }
        } catch (error) {
            console.error("Error fetching marker tiles:", error);
            toast.error("Error fetching marker tiles!");
        }
    };

    const debouncedFetchMarkerTiles = debounce(fetchMarkerTiles, 200);

    useEffect(() => {
        map.on("moveend", debouncedFetchMarkerTiles);
        return () => {
            map.off("moveend", debouncedFetchMarkerTiles);
        };
    }, [map, debouncedFetchMarkerTiles]);

    // Effect for handling updates based on filter changes
    useEffect(() => {
        fetchMarkerTiles();
    }, [filters]);

    useEffect(() => {
        // This effect only runs once on unmount
        return () => {
            // Remove layers that are no longer visible
            Object.keys(layersRef.current).forEach((geohash) => {
                map.removeLayer(layersRef.current[geohash]);
                delete layersRef.current[geohash];
            });
        };
    }, []); // Empty dependency array means this effect runs only on unmount

    return null;
};

export default MarkerTiles;
