import React from 'react';
import { Box, Typography } from '@mui/material';
import { legendShapes } from '../../../utilities/legendShapes';

const MapLegend = () => {
  const legends = [
    { name: 'Apartment', shape: 'triangle' },
    { name: 'House', shape: 'rotatedTriangle' },
    { name: 'Land', shape: 'circle' },
    { name: 'Hotel', shape: 'square' },
    { name: 'Commercial', shape: 'rotatedSquare' },
  ];

  return (
    <Box sx={{
      position: 'absolute',
      bottom: 16,
      left: 16,
      backgroundColor: 'white',
      padding: 1.5,
      borderRadius: 1,
      boxShadow: 1,
      zIndex: 1000,
    }}>
      {legends.map((legend) => (
        <Box key={legend.name} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
          <Box sx={{ ...legendShapes[legend.shape], }}></Box>
          <Typography variant="body1">{legend.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MapLegend;
