import React, { useState } from "react";
import {
    MapContainer,
    TileLayer,
    ZoomControl,
    useMapEvent,
} from "react-leaflet";

import MapSearchBox from "./toolbar/mapSearchBox";
import MarkerClusters from "./markers/markerClusters";
import MarkerTiles from "./markers/markerTiles";
import MapLegend from "./toolbar/mapLegend";

import {
    propertyTypeChoices,
    transactionTypeChoices,
} from "../../utilities/choices";

import "leaflet/dist/leaflet.css";
import '../../assets/css/map.css';

export const FilterContext = React.createContext();

const MapBox = () => {
    const [zoomLevel, setZoomLevel] = useState(12);

    const [filters, setFilters] = useState({
        priceRange: [null, null],
        areaRange: [null, null],
        selectedPropertyTypes: [propertyTypeChoices[0].value],
        selectedTransactionTypes: [transactionTypeChoices[0].value],
    });

    // Initial map center and bounds for Georgia
    const initialGeorgiaCenter = [41.724689, 44.839681];
    const georgiaBounds = [
        [38.4386, 39.1181],
        [45.6458, 48.5299],
    ];

    // Function to handle map zoom event
    const handleZoomChange = (event) => {
        setZoomLevel(event.target._zoom);
    };

    return (
        <MapContainer
            style={{ height: "100%", width: "100%" }}
            zoom={zoomLevel}
            zoomControl={false}
            minZoom={8}
            maxZoom={18}
            center={initialGeorgiaCenter}
            maxBounds={georgiaBounds}
            maxBoundsViscosity={1.0}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />

            {/* Zoom control component */}
            <ZoomControl position="bottomright" />

            {/* Legend */}
            <MapLegend />

            {/* Filter component, controlled by a single source of truth */}
            <FilterContext.Provider value={{ filters, setFilters}} >
                <MapSearchBox />
                
                {/* Conditional rendering of markers based on zoom level */}
                {zoomLevel <= 16 ? <MarkerClusters /> : <MarkerTiles />}
            </FilterContext.Provider>

            {/* Hook to handle map zoom event */}
            <MapEventDispatcher onZoomEnd={handleZoomChange} />
        </MapContainer>
    );
};

// Custom component to handle map events
const MapEventDispatcher = ({ onZoomEnd }) => {
    useMapEvent("zoomend", onZoomEnd); // Listen to 'zoomend' event and trigger onZoomEnd function
    return null;
};

export default MapBox;
