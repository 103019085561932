import React, { useContext } from "react";

import authService from "../../services/authService";
import { AuthContext } from "../../contexts/authProvider";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Box,
  Typography,
  Link as MuiLink,
  InputAdornment,
} from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";

import SubmitButton from "../../components/forms/fields/submitButton";
import GenericFormContainer from "../../components/forms/genericFormContainer";
import { loginValidationSchema } from "../../utilities/validationSchemas";

const LoginPage = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { updateUserState } = useContext(AuthContext);

  const nextRoute = new URLSearchParams(location.search).get("next");

  const handleLoginSuccess = async (values, { setSubmitting }) => {
    const response = await authService.login(values.email, values.password);

    setSubmitting(false);
    if (response.success) {
      localStorage.setItem("jwt-access", response.data.access);
      localStorage.setItem("jwt-refresh", response.data.refresh);

      await updateUserState();

      toast.success("თქვენ წარმატებით გაიარეთ ავტორიზაცია!");

      navigate(nextRoute || "/", { replace: true });
      return;
    }

    toast.error(response.error.detail);
  };

  return (
    <GenericFormContainer>
      <Box
        sx={{
          width: "100%",
          marginBottom: 8,
          py: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          color="grey.dark"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "1.5rem",
              sm: "2.25rem",
            }
          }}
        >
          {t("loginPage.login")}
        </Typography>

        <Typography variant="body1" textAlign="center" fontWeight="bold">
          {t("loginPage.noAccount")}

          <MuiLink
            component={Link}
            to="/register"
            sx={{
              border: "2px solid",
              padding: "0.25em .75em",
              borderRadius: "10px",
              ml: .5,
              color: "grey.dark",
              textDecoration: "none",
            }}
          >
            {t("loginPage.register")}
          </MuiLink>
        </Typography>
      </Box>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginValidationSchema}
        onSubmit={handleLoginSuccess}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Box marginBottom={2}>
              <Field
                as={TextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                type="email"
                name="email"
                label={t("loginPage.fields.email")}
                variant="outlined"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                fullWidth
              />
            </Box>

            <Box marginBottom={2}>
              <Field
                as={TextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                type="password"
                name="password"
                label={t("loginPage.fields.password")}
                variant="outlined"
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                fullWidth
              />
            </Box>

            <Typography
              variant="body1"
              textAlign="right"
              m={2}
              fontWeight="bold"
            >
              <MuiLink
                component={Link}
                to="/request-reset-password"
                sx={{ color: "grey.dark" }}
              >
                {t("loginPage.forgotPassword")}
              </MuiLink>
            </Typography>

            <SubmitButton disabled={isSubmitting}>
              {t("loginPage.login")}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </GenericFormContainer>
  );
};

export default LoginPage;
