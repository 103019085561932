import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Grid, Button, TextField, InputAdornment, Typography, Link as MuiLink } from '@mui/material';
import { useTranslation } from "react-i18next";

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LockIcon from '@mui/icons-material/Lock';


const BasicInformationFormStage = () => {
    const { t } = useTranslation();


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Field
                        as={TextField}
                        name="firstName"
                        type="text"
                        label={t("registerPage.basicInformationFormStage.firstName")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="firstName"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field
                        as={TextField}
                        name="lastName"
                        type="text"
                        label={t("registerPage.basicInformationFormStage.lastName")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="lastName"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
                {/* Email */}
                <Grid item xs={12}>
                    <Field
                        as={TextField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MailOutlineIcon />
                                </InputAdornment>
                            ),
                        }}
                        name="email"
                        type="email"
                        label={t("registerPage.basicInformationFormStage.email")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>

                {/* Phone */}
                <Grid item xs={12}>
                    <Field
                        as={TextField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon />
                                </InputAdornment>
                            ),
                        }}
                        name="phone"
                        type="text"
                        label={t("registerPage.basicInformationFormStage.phoneNumber")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>

                {/* Password and Confirm Password */}
                <Grid item xs={12} sm={6}>
                    <Field
                        as={TextField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        name="password"
                        type="password"
                        label={t("registerPage.basicInformationFormStage.password")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="password"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field
                        as={TextField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        name="confirmPassword"
                        type="password"
                        label={t("registerPage.basicInformationFormStage.confirmPassword")}
                        fullWidth
                    />
                    <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        style={{ color: "tomato" }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default BasicInformationFormStage;
