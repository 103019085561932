import React from 'react';
import { Box, TextField, Button, InputAdornment } from '@mui/material';
import { Form, Formik, Field } from 'formik';

import authService from '../../../services/authService';

import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import { profileSettingsDeleteUserValidationSchema } from '../../../utilities/validationSchemas';

const DeleteAccount = () => {
    const { t } = useTranslation();

    const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
        const response = await authService.deleteAccount(values.currentPassword);
        if (response.success) {
            // Redirect to login page
            window.location.href = '/';
        } else {
            // Display error messages
            if (response.error?.current_password) setFieldError('currentPassword', t(response.error.current_password[0]));
        }
    };

    return (
        <Box>
            <h2>{t("profileSettings.deleteAccount.title")}</h2>
            <p>{t("profileSettings.deleteAccount.description")}</p>

            <Formik
                initialValues={{ currentPassword: '' }}
                validationSchema={profileSettingsDeleteUserValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, touched, errors }) => (
                    <Form>
                        <Box
                            sx={{
                                maxWidth: "100%",
                                width: { xs: '100%', sm: '600px' }
                            }}
                        >
                            <Box marginBottom={2}>
                                <Field
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <LockIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    as={TextField}
                                    type="password"
                                    name="currentPassword"
                                    label={t("profileSettings.deleteAccount.fields.currentPassword")}
                                    variant="outlined"
                                    error={touched.currentPassword && !!errors.currentPassword}
                                    helperText={touched.currentPassword && errors.currentPassword}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            color="error"
                            disabled={isSubmitting}
                            sx={{
                                p: 1.2,
                                fontSize: '1em',
                            }}
                        >
                            {t("profileSettings.deleteAccount.deleteMyAccount")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default DeleteAccount;