import React from "react";

import {IconButton, MenuItem, Tooltip} from "@mui/material";
import {Translate} from "@mui/icons-material";

import GEFlag from "../../assets/media/flags/GE.png";
import UKFlag from "../../assets/media/flags/UK.png";
import AEFlag from "../../assets/media/flags/AE.png";

import i18n from "../../services/i18n";


const LanguageSwitcher = () => {
    const handleChangeLanguage = async (language) => {
        await i18n.changeLanguage(language);
        localStorage.setItem("language", language);
        window.location.reload();
    };

    return (
        <Tooltip
            title={
                <React.Fragment>
                    <MenuItem onClick={() => handleChangeLanguage("ka")}>
                        <img
                            src={GEFlag}
                            alt="Georgia"
                            style={{marginRight: 8, width: 25}}
                        />
                        ქართული
                    </MenuItem>
                    <MenuItem onClick={() => handleChangeLanguage("en")}>
                        <img
                            src={UKFlag}
                            alt="United Kingdom"
                            style={{marginRight: 8, width: 25}}
                        />
                        English
                    </MenuItem>
                    {/*<MenuItem onClick={() => handleChangeLanguage("ar")}>*/}
                    {/*    <img*/}
                    {/*        src={AEFlag}*/}
                    {/*        alt="United Arab Emirates"*/}
                    {/*        style={{marginRight: 8, width: 25}}*/}
                    {/*    />*/}
                    {/*    عربي*/}
                    {/*</MenuItem>*/}
                </React.Fragment>
            }
        >
            <IconButton color="inherit">
                <Translate
                    sx={{
                        fontSize: {
                            xs: 20,
                            sm: 25,
                            md: 30,
                        }
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}

export default LanguageSwitcher;