import React from "react";

import {Box, CircularProgress} from "@mui/material";

const LoadingPage = () => {
    return (
        <Box
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
            }}
        >
            <CircularProgress size="3em"/>
        </Box>
    );
};

export default LoadingPage;
