import React from 'react';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { Box, Button, TextField, InputAdornment, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

import contentService from '../../../services/contentService';
import { licenseValidationSchema } from '../../../utilities/validationSchemas';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const LicenseFormStage = ({ setIsForwardEnabled, setCurrentStep }) => {
  const { t } = useTranslation();
  const [hasConfirmedLicense, setHasConfirmedLicense] = React.useState(false);
  const { values, errors } = useFormikContext();

  const handleLicenseVerification = async () => {
    try {
      await licenseValidationSchema.validate({ license: values.license })
    } catch (validationResult) {
      toast.error(validationResult.errors[0]);
      return;
    }

    // If key is ok, check it with the server
    const response = await contentService.checkLicenseKeyValidity(values.license);

    if (!response.success) {
      console.log(response);
      response.error?.license_key?.forEach((error) => { toast.error(error); });
    } else {
      toast.success(response.data.message);

      // Toggle Flags
      setHasConfirmedLicense(true);
      setIsForwardEnabled(true);
      setCurrentStep(prev => prev + 1);
    }
  }

  return (
    <>
      <Box display="flex" gap={1} mt="3rem">
        <Field
          as={TextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKeyIcon color={hasConfirmedLicense ? "success" : "primary"} />
              </InputAdornment>
            ),
          }}
          name="license"
          type="text"
          color={hasConfirmedLicense ? "success" : "primary"}
          label={t("registerPage.licenseFormStage.license")}
          fullWidth
          focused
        />

        <Button
          variant="contained"
          color="primary"
          disabled={hasConfirmedLicense || errors.license !== undefined}
          onClick={handleLicenseVerification}
          sx={{
            minWidth: 80
          }}
        >
          <Typography fontWeight="bold">
            {t("registerPage.licenseFormStage.verify")}
          </Typography>
        </Button>
      </Box>

      <ErrorMessage
          name="license"
          component="div"
          style={{ color: "tomato" }}
        />

      <Typography variant="body1" sx={{ mt: 4, fontWeight: "bold", textAlign: 'center' }}>
        {t("registerPage.licenseFormStage.dontHaveLicense")}
        {' '}
        <MuiLink component={Link} to="/contact">
          {t("registerPage.licenseFormStage.contactUs")}
        </MuiLink>
      </Typography>
    </>
  );
};

export default LicenseFormStage;
