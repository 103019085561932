import React from 'react';

// # TODO: Implement the resend verification logic
const ResendVerification = () => {
  return (
    <div>ResendVerification</div>
  );
};

export default ResendVerification;
