import React from "react";

import {
  Box,
  useMediaQuery,
  useTheme
} from "@mui/material";

import bgHorizontal from "../../assets/media/backgrounds/bgHorizontal.jpg";
import bgVertical from "../../assets/media/backgrounds/bgVertical.jpg";
import estrealLogo from "../../assets/media/logos/logoIconWhite.png";

const GenericFormContainer = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        backgroundImage: `url(${matches ? bgVertical : bgHorizontal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column-reverse" : "row",
          my: {
            xs: 1,
          },
          maxWidth: "95%",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: {
              xs: "0 0 25px 25px",
              sm: "25px 0 0 25px",
            },
            padding: "2em 3em",
            minHeight: "55vh",
            width: "700px",
            maxWidth: "100%",
          }}
        >
            {children}
        </Box>
        <Box
          sx={{
            display: "relative",
            backgroundImage: {
              xs: 'none',
              sm: `url(${bgHorizontal})`,
            },
            backgroundColor: "grey.dark",
            backgroundSize: "cover",
            width: {
              xs: "100%",
              sm: "400px",
            },
            borderRadius: {
              xs: "25px 25px 0 0",
              sm: "0 25px 25px 0",
            },
          }}
        >
          <img
            src={estrealLogo}
            alt="Estreal Logo"
            style={{ float: "right", width: "80px", height: "80px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GenericFormContainer;
