import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';


import { AuthContext } from '../../contexts/authProvider';
import LoadingPage from '../../views/loadingPage';


const ProtectedRoute = ({ children }) => {
  const { currentUser, isLoading } = useContext(AuthContext);
  const location = useLocation();

  // If the authentication status is still loading, render a loading view
  if (isLoading) return <LoadingPage />;

  // If the user is not authenticated, redirect to the login page
  if (!currentUser) {
    const nextUrl = location.pathname + location.search;

    return <Navigate to={`/login?next=${encodeURIComponent(nextUrl)}`} />;  
  }

  // If the user is authenticated, render the children components
  return children;
};

export default ProtectedRoute;
