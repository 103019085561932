import React, { useState, useCallback } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMap } from 'react-leaflet';

import MapFilterBox from './mapFilterBox';

const MapSearchBox = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const map = useMap();

    // Define the debounce function outside of your debounced function
    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    // Use useCallback to memorize the debounced function
    const updateMapView = useCallback((lat, lon) => {
        map.setView([lat, lon], 15);
    }, [map]);

    const debouncedFetchGeocodeResults = useCallback(debounce(async (searchText) => {
        if (!searchText.trim()) return;

        setIsLoading(true);
        const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchText)}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            const transformedData = data.map(item => ({
                label: item.display_name,
                lat: parseFloat(item.lat),
                lon: parseFloat(item.lon),
            }));

            setOptions(transformedData);

            if (transformedData.length > 0) {
                // Optionally, update the map view to the first result
                updateMapView(transformedData[0].lat, transformedData[0].lon);
            }
        } catch (error) {
            console.error("Failed to fetch geocoding data:", error);
        } finally {
            setIsLoading(false);
        }
    }, 500), [updateMapView]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        debouncedFetchGeocodeResults(newInputValue);
    };

    // Adjust handleFormSubmit if necessary
    const handleFormSubmit = (event) => {
        event.preventDefault();
        debouncedFetchGeocodeResults(inputValue);
    };

    // Adjust handleOptionSelected to call updateMapView
    const handleOptionSelected = (event, selectedOption, reason) => {
        if (reason === 'selectOption' && selectedOption) {
            updateMapView(selectedOption.lat, selectedOption.lon);
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Autocomplete
                freeSolo
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={options}
                onChange={handleOptionSelected}
                getOptionLabel={(option) => option.label || ''}
                loading={isLoading}
                ListboxProps={{
                    sx: {
                        backgroundColor: 'background.default',
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    <MapFilterBox /> {/* Add filter box component */}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: 'white',
                                borderRadius: '50px',
                                width: {
                                    xs: '95%',
                                    sm: '300px',
                                },
                                fontSize: '1.3em',
                                m: 2,
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                '& fieldset': {
                                    border: 'none',
                                },
                                zIndex: 1000
                            }
                        }}
                    />
                )}
            />
        </form>
    );
};

export default MapSearchBox;
