import React from "react";

import {Box, Typography, useTheme} from "@mui/material";
import EmailImage from "../../assets/media/email.png";
import GenericFormContainer from "../../components/forms/genericFormContainer";

const NotifyEmailVerification = () => {
    const theme = useTheme();

    return (
        <GenericFormContainer>
            <Box textAlign="center" py={2}>
                <img src={EmailImage} alt='email-icon-graphic'/>
                <Typography variant="h5" component="h2" marginTop={2}>
                    ვერიფიკაციის ბმული გაგზავნილია!
                </Typography>
                <Typography variant="body1" marginTop={2}>
                    თქვენი მეილის მისამართზე გაგზავნილია ბმული ინსტრუქციით. გთხოვთ
                    შეამოწმეთ თქვენი ელ-ფოსტა და გადადით ბმულზე, რათა
                    შეძლოთ ელ-ფოსტის ვერიფიკაცია.
                </Typography>
            </Box>
        </GenericFormContainer>
    );
};

export default NotifyEmailVerification;
