import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./utilities/theme";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Auth Context Provider
import { AuthProvider } from "./contexts/authProvider";
import { registerShowModalCallback } from "./services/httpService";

// EstReal system pages
import HomePage from "./views/estreal/homePage";
import MapPage from "./views/estreal/mapPage";
import AboutUs from "./views/estreal/aboutUs";
import TermsAndPrivacy from "./views/estreal/termsAndPrivacy";
import Profile from "./views/estreal/ profile";

// Auth pages
import LoginPage from "./views/auth/loginPage";
import LogoutPage from "./views/auth/logoutPage";
import RegisterPage from "./views/auth/registerPage";
import VerifyEmail from "./views/auth/verifyEmail";
import NotifyEmailVerification from "./views/auth/notifyEmailVerification";
import ResendVerification from "./views/auth/resendVerification";
import RequestResetPassword from "./views/auth/requestResetPassword";
import ResetPasswordConfirm from "./views/auth/resetPasswordConfirm";

// Protected route and other components
import NavBar from "./components/navigation/navBar";
import ProtectedRoute from "./components/routes/protectedRoute";
import TokenExpireDialog from "./components/modals/tokenExpireDialog";
import Layout from "./components/layout/layout";

function App() {
  const [showTokenExpiryDialog, setShowTokenExpiryDialog] = useState(false);
  const closeDialog = () => setShowTokenExpiryDialog(false);
  const openDialog = () => setShowTokenExpiryDialog(true);

  useEffect(() => {
    // Register the callback function when the component mounts
    // to be able to show the token expiry dialog from external httpService.js
    registerShowModalCallback(openDialog);
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer position="bottom-right" />
        <TokenExpireDialog open={showTokenExpiryDialog} onClose={closeDialog} />

        <Layout>
          <NavBar />
          <Routes>
            {/* EstReal pages */}
            <Route path="/" element={<HomePage />} />
            <Route
              path="/map"
              element={
                <ProtectedRoute>
                  <MapPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />

            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />

            {/* Auth pages */}
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/logout"
              element={
                <ProtectedRoute>
                  <LogoutPage />
                </ProtectedRoute>
              }
            />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/verify/:uid/:token" element={<VerifyEmail />} />
            <Route
              path="/notify-email-verify"
              element={<NotifyEmailVerification />}
            />
            <Route
              path="/resend-verification"
              element={<ResendVerification />}
            />
            <Route
              path="/request-reset-password"
              element={<RequestResetPassword />}
            />
            <Route
              path="/reset-password/:uid/:token"
              element={<ResetPasswordConfirm />}
            />

            {/* 404 page */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
