import React from "react";


const AboutUs = () => {
    return (
        <p>about us</p>
    );
}

export default AboutUs;
