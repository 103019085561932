import axios from 'axios';
import { toast } from 'react-toastify';

// Create an Axios instance
const httpService = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
});

let showModalCallback = null; // Callback function to show the modal

export const registerShowModalCallback = (callback) => {
  showModalCallback = callback;
};


/**
 * Refreshes the access token using the refresh token stored in the local storage.
 * If the refresh token is valid, it sends a request to the server to get a new access token.
 * If the request is successful, it updates the access token in the local storage and returns the new access token.
 * If the request fails, it processes the error, removes the access and refresh tokens from the local storage,
 * and prompts the user to confirm redirecting to the login page.
 * @returns {Promise<string|null>} The new access token if the refresh is successful, otherwise null.
 */
export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('jwt-refresh');
  if (refreshToken) {
    try {
      const response = await axios.post('auth/jwt/refresh/', { refresh: refreshToken });
      localStorage.setItem('jwt-access', response.data.access);
      return response.data.access;
    } catch (error) {
      localStorage.removeItem('jwt-access');
      localStorage.removeItem('jwt-refresh');

      showModalCallback && showModalCallback();

      return null;
    }
  }
  return null;
};


// Add an interceptor to handle automatic token refresh or network error
httpService.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Check for network error
    if (!error.response) {
      toast.error("Connection error! Please check your internet connection.");
      return Promise.reject(error);
    }

    // Handle token expiration
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      if (newAccessToken) {
        localStorage.setItem('jwt-access', newAccessToken);
        axios.defaults.headers.common['Authorization'] = 'JWT ' + newAccessToken;
        return httpService(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);


export default httpService;
