import React, { useState, useContext } from "react";
import { Button, Popover, Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import TuneIcon from "@mui/icons-material/Tune";

import {
    propertyTypeChoices,
    transactionTypeChoices,
} from "../../../utilities/choices";
import { filterValidationSchema } from "../../../utilities/validationSchemas";

import { FilterContext } from "../mapBox";
import RangeInput from "./rangeInput";

const MapFilterBox = () => {
    // Get the filter state from the context
    const { filters, setFilters } = useContext(FilterContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to toggle selection for property types
    const togglePropertyType = (value, values, setFieldValue) => {
        const selectedPropertyTypes = values.selectedPropertyTypes;
        const index = selectedPropertyTypes.indexOf(value);
        if (index === -1) {
            selectedPropertyTypes.push(value);
        } else {
            selectedPropertyTypes.splice(index, 1);
        }
        setFieldValue("selectedPropertyTypes", selectedPropertyTypes);
    };

    // Function to toggle selection for transaction types
    const toggleTransactionType = (value, values, setFieldValue) => {
        const selectedTransactionTypes = values.selectedTransactionTypes;
        const index = selectedTransactionTypes.indexOf(value);
        if (index === -1) {
            selectedTransactionTypes.push(value);
        } else {
            selectedTransactionTypes.splice(index, 1);
        }
        setFieldValue("selectedTransactionTypes", selectedTransactionTypes);
    };

    const open = Boolean(anchorEl);
    const id = open ? "map-filter-popover" : undefined;

    return (
        <Box>
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                sx={{
                    borderRadius: "50px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                onClick={handleClick}
            >
                <TuneIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Formik
                    initialValues={{
                        priceRange: filters.priceRange,
                        areaRange: filters.areaRange,
                        selectedPropertyTypes: filters.selectedPropertyTypes,
                        selectedTransactionTypes: filters.selectedTransactionTypes,
                    }}
                    validationSchema={filterValidationSchema}
                    onSubmit={(values) => {
                        setFilters(values);
                        
                        console.log("Filters applied:", values);
                        toast.success("Filters applied!");
                        handleClose(); // Close the filter form/modal
                    }}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Box display="flex" flexDirection="column" p={2}>
                                <RangeInput label="Price" name="priceRange" unit="$" />
                                <RangeInput label="Area" name="areaRange" unit="m²" />
                            </Box>
                            <Box display="flex" px={1}>
                                <Box display="flex" flexDirection="column" width="50%">
                                    <Typography
                                        variant="body1"
                                        textAlign="center"
                                        fontWeight="bold"
                                    >
                                        Property Type
                                    </Typography>
                                    {propertyTypeChoices.map((type) => (
                                        <Button
                                            key={type.value}
                                            variant={
                                                filters.selectedPropertyTypes.includes(type.value)
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            color="primary"
                                            onClick={() =>
                                                togglePropertyType(type.value, values, setFieldValue)
                                            }
                                            sx={{
                                                m: 1,
                                                py: 1,
                                                borderRadius: 15,
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontWeight="bold"
                                                component="span"
                                            >
                                                {type.label}
                                            </Typography>
                                        </Button>
                                    ))}
                                </Box>
                                <Box display="flex" flexDirection="column" width="50%">
                                    <Typography
                                        variant="body1"
                                        textAlign="center"
                                        fontWeight="bold"
                                    >
                                        Transaction Type
                                    </Typography>
                                    {transactionTypeChoices.map((type) => (
                                        <Button
                                            key={type.value}
                                            variant={
                                                filters.selectedTransactionTypes.includes(type.value)
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            color="primary"
                                            onClick={() =>
                                                toggleTransactionType(type.value, values, setFieldValue)
                                            }
                                            sx={{
                                                m: 1,
                                                py: 1,
                                                borderRadius: 15,
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontWeight="bold"
                                                component="span"
                                            >
                                                {type.label}
                                            </Typography>
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                            
                            {Object.keys(errors).length > 0 && (
                                <Box display="flex" flexDirection="column" justifyContent="center" m={2}>
                                    {Object.keys(errors).map((error) => (
                                        <Typography key={error} color="error" variant="body2">
                                            {errors[error]}
                                        </Typography>
                                    ))}
                                </Box>
                            )}

                            <Box display="flex" justifyContent="center" m={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={Object.keys(errors).length > 0}
                                    sx={{
                                        borderRadius: 15,
                                        p: ".5em 1em",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        mr={1}
                                        fontWeight="bold"
                                    >
                                        Apply
                                    </Typography>
                                    <TuneIcon />
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Popover>
        </Box>
    );
};

export default MapFilterBox;
