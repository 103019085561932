
export const calculateColor = (price, objectivePrice) => {
    if (objectivePrice == null) {
        return "#2D5FB9"; // Soft blue
    }

    const difference = objectivePrice - price;
    const percentageDifference = (difference / price) * 100;

    // Helper function to interpolate between two values
    function interpolate(start, end, factor) {
        return Math.round(start + (end - start) * factor);
    }

    // Red, yellow, and green color stops
    const colors = [
        { r: 244, g: 67, b: 54 },   // red
        { r: 249, g: 215, b: 28 },  // yellow
        { r: 76, g: 175, b: 80 }    // green
    ];

    let r, g, b;

    if (percentageDifference <= -50) {
        // More than 50% below AI price, use solid red
        ({ r, g, b } = colors[0]);
    } else if (percentageDifference >= 50) {
        // More than 50% above AI price, use solid green
        ({ r, g, b } = colors[2]);
    } else if (percentageDifference <= 0) {
        // Between -50% and 0% difference, interpolate between red and yellow
        const factor = (percentageDifference + 50) / 50;  // Maps -50 to 0 to 0 to 1
        r = interpolate(colors[0].r, colors[1].r, factor);
        g = interpolate(colors[0].g, colors[1].g, factor);
        b = interpolate(colors[0].b, colors[1].b, factor);
    } else {
        // Between 0% and 50% difference, interpolate between yellow and green
        const factor = percentageDifference / 50;  // Maps 0 to 50 to 0 to 1
        r = interpolate(colors[1].r, colors[2].r, factor);
        g = interpolate(colors[1].g, colors[2].g, factor);
        b = interpolate(colors[1].b, colors[2].b, factor);
    }

    // Convert RGB values to hex
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}