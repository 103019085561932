import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Box, Typography, TextField, Link as MuiLink, InputAdornment } from "@mui/material";
import { Formik, Form, Field } from "formik";

import GenericFormContainer from "../../components/forms/genericFormContainer";
import SubmitButton from "../../components/forms/fields/submitButton";
import authService from "../../services/authService";

import { requestResetPasswordValidationSchema } from "../../utilities/validationSchemas"; // assuming you have a validation schema

import MailOutlineIcon from "@mui/icons-material/MailOutline";

const RequestResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRequestReset = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      const response = await authService.requestPasswordReset(values.email);

      if (response.success) {
        // Notify the user with a message view
        navigate("/notify-email-verify", { replace: true });
      } else {
        // Handle failure (show error message)
        toast.error(
          response.error || "დაფიქსირდა შეცდომა, მოგვიანებით სცადეთ."
        );
      }
    } catch (error) {
      toast.error("დაფიქსირდა შეცდომა, მოგვიანებით სცადეთ.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <GenericFormContainer>
      <Box
        sx={{
          width: "100%",
          marginBottom: 8,
          py: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          color="grey.dark"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "1.5rem",
              sm: "2.25rem",
            }
          }}
        >
          {t("requestResetPassword.resetPassword")}
        </Typography>

        <Typography variant="body1" textAlign="center" fontWeight="bold">
          <MuiLink
            component={Link}
            to="/contact"
            sx={{
              border: "2px solid",
              padding: "0.25em .75em",
              borderRadius: "10px",              
              color: "grey.dark",
              textDecoration: "none",
            }}
          >
            {t("requestResetPassword.needSupport")}
          </MuiLink>
        </Typography>
      </Box>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={requestResetPasswordValidationSchema}
        onSubmit={handleRequestReset}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Box marginBottom={2}>
              <Field
                as={TextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                type="email"
                name="email"
                label={t("requestResetPassword.fields.email")}
                variant="outlined"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                fullWidth
              />
            </Box>


            <SubmitButton disabled={isSubmitting}>
              {t("requestResetPassword.submit")}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </GenericFormContainer>
  );
};

export default RequestResetPassword;
